@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@300;500&family=Open+Sans&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Archivo", sans-serif;
}
/* 
.fflogo {
  height: 100px;
  width: 100px !important;
} */

.abccc {
  background: linear-gradient(180deg, #54d86a 0%, #2379f3 47.92%, #ed507c 100%);
}

.mmt {
  margin-top: 7rem;
}

.btn-green,
.btn-green:focus {
  background-color: #54d86a;
  color: #fff;
}
.btn-green:hover,
.btn-green:active,
.btn-green:focus:hover,
.btn-green:focus:active {
  color: #fff;
  background-color: #54d86a;
}

.customecard {
  border: none;
}

.small-text {
  font-size: 1.6rem !important;
}

.circle {
  background-color: #ebf1fe;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.infopanel {
  background-color: #d3ebff;
  padding: 5rem 0;
}

.infopanel-title {
  font-size: 3rem;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  border: 3px solid #000;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  z-index: -1;
}

/* Container around content */
.containerr {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.containerr::after {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  right: -10px;
  background-color: #fff;
  border: 4px solid #000;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -10px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  position: relative;
  border-radius: 6px;
}

.showcasemargintop {
  margin-top: 8rem;
}

@media only screen and (max-width: 600px) {
  .rmt {
    margin-top: 3rem;
  }

  .showcasemargintop {
    margin-top: 0rem;
  }

  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .containerr {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .containerr::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 20px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}
